@keyframes blink {
    from {
        opacity: 1;
    }

    49.9% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    99.9% {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
@media (prefers-color-scheme: dark) {
    #caret {
        background-color: white !important;
    }
    .text-spacer {
        color: black !important;
    }
}
